import { useAuth0 } from '@auth0/auth0-react';
import { getBookings } from 'api/admin';
import DropDownMultiSelector from 'components/common/dropDownMultiSelector';
import { NextIcon } from 'components/icons/icons';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import Paging from 'components/common/paging';
import React, { useEffect, useState } from 'react';
import InputSearch from 'components/common/inputSearch';
import Highlighter from 'react-highlight-words';

const BookingsPage = () => {
    const { getAccessTokenSilently} = useAuth0();
    const [isLoading, setIsLoading] = useState();
    const [bookings, setBookings] = useState();
    const [filteredBookings, setFilteredBookings] = useState();
    const [bookingsPages, setBookingsPages] = useState();
    const [currentBookingsPage, setCurrentBookingsPage] = useState(0);
    const [searchText, setSearchText] = useState();
    const [parkingAreaBookingsBuckets, setParkingAreaBookingsBucket] = useState();
    const [parkingAreaOptions, setParkingAreaOptions] = useState();

    useEffect(() => {
        const load = async () => {
            try {
                var token = await getAccessTokenSilently();
                var result = await getBookings(token);

                if (result && !result.error) {
                    setParkingAreaOptions(result.parkingAreaBookingsBuckets.map((item) => {
                        return { value: item.parkingAreaExtId, title: `${item.parkingAreaName} (${item.numberOfBookings})`, isChecked: false }
                    }));
                    setBookings(result.bookings);
                    setFilteredBookings(result.bookings);
                    setParkingAreaBookingsBucket(result.parkingAreaBookingsBuckets);
                    setBookingsPages(Math.ceil(result.bookings.length / 10));
                    setCurrentBookingsPage(0);
                } else {
                    console.error(result);
                }
            } catch (error) {
                console.error(error);
            }

            setIsLoading(false);
        }

        setIsLoading(true);
        load();
    }, [getAccessTokenSilently]);

    const handleParkingAreaOptionsSelected = (parkingAreaExtId) => {
        const newOptions = parkingAreaOptions.map((option) => {
            if (option.value === parkingAreaExtId) {
                option.isChecked = !option.isChecked;
            }
            return option;
        });

        setParkingAreaOptions(newOptions);
    }

    useEffect(() => {
        if (bookings) {
            var filteredBookings = bookings;

            if (searchText) {
                filteredBookings = filteredBookings.filter((booking) => {
                    return booking.registrationIdentifier?.toLowerCase().includes(searchText.toLowerCase()) || 
                        booking.parkingSpotIdentifier?.toLowerCase().includes(searchText.toLowerCase()) || 
                        booking.bookingExtId?.toLowerCase().includes(searchText.toLowerCase());
                });
            }

            if (parkingAreaOptions && parkingAreaOptions.some((option) => option.isChecked)) {
                filteredBookings = filteredBookings.filter((booking) => {
                    return parkingAreaOptions.some((option) => {
                        return option.value === booking.parkingAreaExtId && option.isChecked;
                    });
                });
            }
            
            setFilteredBookings(filteredBookings);
            setBookingsPages(Math.ceil(filteredBookings.length / 10));
            setCurrentBookingsPage(0);
        }
    }, [searchText, parkingAreaOptions, bookings]);

    if (filteredBookings && parkingAreaBookingsBuckets && !isLoading) {
        return (
            <div className="flex flex-col w-full rounded-xl shadow-lg p-6">
                <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-6 gap-y-3">
                    <div className="flex flex-col md:w-1/2">
                        <h2 className="text-xl font-medium">Active bookings</h2>
                        <p className="text-gray-500 mt-1">Here you can see all active and upcoming bookings.</p>
                    </div>
                    <div className="flex items-center gap-3">
                        <InputSearch
                            placeHolder={'Search...'}
                            value={searchText ?? ''}
                            onChange={(value) => { setSearchText(value); }} />
                    </div>
                    <div className="md:w-1/2 w-full ml-3">
                        <DropDownMultiSelector
                            onOptionSelected={(option) => {
                                handleParkingAreaOptionsSelected(option.value);
                            }}
                            options={parkingAreaOptions} />
                    </div>
                </div>
                <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                    <table className="table-auto mt-3 w-full">
                        <thead>
                            <tr className="text-left bg-airpark-gray-300 h-11">
                                <th className="pl-6 whitespace-nowrap">Id</th>
                                <th className="pl-6 whitespace-nowrap">Parking area</th>
                                <th className="pl-6 whitespace-nowrap">Parking spot</th>
                                <th className="pl-6 whitespace-nowrap">Period</th>
                                <th className="pl-6 whitespace-nowrap">Amount</th>
                                <th className="pl-6 pr-6 whitespace-nowrap">Registration identifier</th>
                                <th className="pl-6 whitespace-nowrap"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBookings && filteredBookings.slice(currentBookingsPage * 10, ((currentBookingsPage * 10) + 10)).map((item, i) => {
                                return (<BookingItem key={i} booking={item} searchText={searchText} />)
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="p-6">
                    <Paging
                        totalNumberOfPages={bookingsPages}
                        currentPage={currentBookingsPage}
                        setCurrentPage={setCurrentBookingsPage}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <LoadingSkeleton />
        )
    }
};

const BookingItem = ({ booking, searchText }) => {
    const url = `${process.env.REACT_APP_WEB_PORTAL_BASE_URL}/${booking.organizationExtId}/parking-areas/${booking.parkingAreaExtId}/bookings?bookingExtId=${booking.bookingExtId}`
    
    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}                    
                    textToHighlight={booking.bookingExtId}
                />
            </td>
            <td className="pl-6">{booking.parkingAreaName}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={booking.parkingSpotIdentifier ?? ""}
                />
            </td>
            <td className="pl-6 whitespace-nowrap">{booking.period}</td>
            <td className="pl-6 whitespace-nowrap">{booking.amountPaid}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={booking.registrationIdentifier}
                />
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                <a className="flex h-16 w-full justify-center items-center" href={url} target="_blank">
                    <NextIcon />
                </a>
            </td>
        </tr>
    )
}

export default BookingsPage;