const BASE_URL = `${process.env.REACT_APP_API_ADMIN_BASE_URL}/Admin`
const BASE_SUPPORT_BOT_URL = `${process.env.REACT_APP_API_ADMIN_BASE_SUPPORT_BOT_URL}/Admin/Supportbot`

export const getParkingAreas = async (token) => {
  const response = await fetch(BASE_URL + '/parking-areas', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const getParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/parking-area/${parkingAreaExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const deleteParkingArea = async (token, parkingAreaExtId) => {
  const response = await fetch(BASE_URL + `/parking-area/${parkingAreaExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }
}

export const updateParkingAreaStatus = async (token, parkingAreaStatusUpdate) => {
  const response = await fetch(BASE_URL + `/update-parking-area-status`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(parkingAreaStatusUpdate)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getControlFees = async (token) => {
  const response = await fetch(BASE_URL + '/control-fees', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const getControlFee = async (token, controlFeeExtId) => {
  const response = await fetch(BASE_URL + `/control-fee/${controlFeeExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response
}

export const deleteControlFee = async (token, controlFeeExtId) => {
  const response = await fetch(BASE_URL + `/control-fee/${controlFeeExtId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return response.json();
    } else {
      return {};
    }
  }
}

export const updateControlFeeStatus = async (token, controlFeeStatusUpdate) => {
  const response = await fetch(BASE_URL + `/update-control-fee-status`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(controlFeeStatusUpdate)
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getBookings = async (token) => {
  const response = await fetch(BASE_URL + '/get-parking-area-bookings', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getCurrentUserTestToken = async (token) => {
  const response = await fetch(BASE_URL + '/get-current-user-test-token', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.text();
  }

  throw response
}

export const updateUserTestToken = async (token, updatedToken) => {
  const response = await fetch(BASE_URL + '/update-user-test-token', {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedToken)
  })

  if (response.status === 200) {
    return response.text();
  }

  throw response
}

export const getOrganizations = async (token) => {
  const response = await fetch(BASE_URL + '/organizations', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const joinOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/organizations/${organizationExtId}/users`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }

  throw response;
}

export const leaveOrganization = async (token, organizationExtId) => {
  const response = await fetch(BASE_URL + `/organizations/${organizationExtId}/users`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }

  throw response;
}

export const getUnreadNotifications = async (token) => {
  const response = await fetch(BASE_URL + '/unread-notifications', {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const markNotificationAsRead = async (token, notification) => {
  const response = await fetch(BASE_URL + `/mark-notification-as-read`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(notification)
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }

  throw response;
}

export const markNotificationAsUnread = async (token, notification) => {
  const response = await fetch(BASE_URL + `/mark-notification-as-unread`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(notification)
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }

  throw response;
}

export const markPendingNotificationsAsUnread = async (token, notificationType) => {
  const response = await fetch(BASE_URL + `/mark-pending-notifications-as-unread/${notificationType}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }

  throw response;
}

export const markAllNotificationsAsRead = async (token, notificationType) => {
  const response = await fetch(BASE_URL + `/mark-all-notifications-as-read/${notificationType}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var responseText = await response.text();

    if (responseText != '') {
      return JSON.parse(responseText);
    } else {
      return {};
    }
  }

  throw response;
}

export const getAdmins = async (token) => {
  const response = await fetch(BASE_URL + `/admins`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createAdmin = async (token, id) => {
  const response = await fetch(BASE_URL + `/admins/${id}`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var success = await response.text();
    return success.toLowerCase() === 'true';
  }

  throw response;
}

export const removeAdmin = async (token, id) => {
  const response = await fetch(BASE_URL + `/admins/${id}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    var success = await response.text();
    return success.toLowerCase() === 'true';
  }

  throw response;
}


export const searchForUsers = async (token, searchString) => {
  const response = await fetch(BASE_URL + `/users/search/${searchString}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const getAirPasses = async (token) => {
  const response = await fetch(BASE_URL + `/airpasses`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const createAirPass = async (token, airPass) => {
  const response = await fetch(BASE_URL + `/airpasses`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(airPass)
  })

  if (response.status === 200) {
    return true;
  }

  throw response;
}

export const openPassage = async (token, airPassExtId) => {
  const response = await fetch(BASE_URL + `/airpasses/${airPassExtId}/open`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return true;
  }

  throw response;
}

export const searchForOrganizations = async (token, searchString) => {
  const response = await fetch(BASE_URL + `/organizations/search/${searchString}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}


export const deletePoint = async ({ token, pointId }) => {
  const response = await fetch(BASE_SUPPORT_BOT_URL + `/point/${pointId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const updatePoint = async ({ token, updatedPoint }) => {
  const response = await fetch(BASE_SUPPORT_BOT_URL + `/point`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify(updatedPoint)
  });

  if (response.status === 201 || response.status === 200) {
    return response.json();
  }

  throw response;
}

export const postPoint = async ({ token, newPoint, useGenAI = false }) => {
  const response = await fetch(BASE_SUPPORT_BOT_URL + `/point`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      point: newPoint,
      useGenAI
    })
  });

  if (response.status === 201) {
    const data = await response.json();
    return data;
  }

  throw response;
}

export const getPoints = async ({ token }) => {
  const response = await fetch(BASE_SUPPORT_BOT_URL + `/points`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'authorization': `Bearer ${token}`
    }
  });

  if (response.status === 200) {
    const data = await response.json();
    return data;
  }
  throw response;
}