import React from "react";
import Widget from "../widget/widget";

const WidgetLoadingSkeleton = () => {
    return (
        <Widget>
            <div className="flex flex-row items-center animate-pulse grid-cols-3 gap-4">
                <div className="h-4 w-4 rounded-full bg-slate-200"></div>
                <div className="max-w-xs w-full h-4 bg-slate-200"></div>
                <div className="grow h-4 bg-slate-200"></div>
            </div>
        </Widget>
    )
}

export default WidgetLoadingSkeleton;