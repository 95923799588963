import React from "react";

const Widget = ({ children, className }) => {

    if (className) {
        className = `flex flex-col p-6 shadow rounded-3xl ${className}`
    } else {
        className = "flex flex-col p-6 shadow rounded-3xl"
    }

    return (
        <div className={className}>
            {children}
        </div>
    );
}

export default Widget;