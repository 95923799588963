import { useAuth0 } from '@auth0/auth0-react';
import { getAdmins, removeAdmin } from 'api/admin';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import { ThreeDotsIcon, AddUserIcon } from 'components/icons/icons';
import React, { useEffect, useState } from 'react';
import InputSearch from 'components/common/inputSearch';
import Highlighter from 'react-highlight-words';
import Menu from 'components/dashboard/menu/menu';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';
import Lottie from 'lottie-react';
import NewAdminModal from 'components/pageSpecific/admins/newAdminModal';
import { useMessages } from 'context/messages';
import Paging from 'components/common/paging';

const AdminsPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState();
    const [admins, setAdmins] = useState();
    const [adminPages, setAdminPages] = useState(1);
    const [currentAdminPage, setCurrentAdminPage] = useState(0);
    const [filteredAdmins, setFilteredAdmins] = useState();
    const [searchText, setSearchText] = useState();
    const { showToast } = useMessages();

    const [showNewAdminModal, setShowNewAdminModal] = useState();

    useEffect(() => {
        setIsLoading(true);
        refreshAdmins();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (admins) {
            var filteredAdmins = admins;

            if (searchText) {
                filteredAdmins = filteredAdmins.filter((admin) => {
                    return admin.name.toLowerCase().includes(searchText.toLowerCase())
                        || admin.email?.toLowerCase().includes(searchText.toLowerCase());
                });
            }

            setFilteredAdmins(filteredAdmins);
            setAdminPages(Math.ceil(filteredAdmins.length / 10));
            setCurrentAdminPage(0);
        }
    }, [searchText, admins]);

    const refreshAdmins = async () => {
        try {
            var token = await getAccessTokenSilently();
            var result = await getAdmins(token);

            if (result && !result.error) {
                setAdmins(result);
                setFilteredAdmins(result);
                setAdminPages(Math.ceil(result.length / 10));
                setCurrentAdminPage(0);
            } else {
                console.error(result);
            }
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    const handleAdminCreated = async (name) => {
        setIsLoading(true);
        await refreshAdmins();
        showToast('Admin created!', `Successfully added '${name}' as admin!`, 'success');
    }

    const handleAdminRemoved = async (name) => {
        setIsLoading(true);
        await refreshAdmins();
        showToast('Admin removed!', `Successfully removed admin rights from '${name}'!`, 'success');
    }

    if (filteredAdmins && !isLoading) {
        return (
            <div className="flex flex-col w-full rounded-xl shadow-lg p-6">
                <div className="flex md:flex-row flex-col md:justify-between md:items-center mb-6 gap-y-3">
                    <div className="flex gap-x-12 md:w-full">
                        <h2 className="text-xl font-medium">Administrators</h2>
                    </div>
                    <div className="fill-white mr-3">
                        <InputSearch
                            placeHolder={'Search...'}
                            value={searchText ?? ''}
                            onChange={(value) => { setSearchText(value); }} />
                    </div>
                    <div className="flex items-center gap-3">
                        <button className="h-6 w-6" onClick={(e) => { e.preventDefault(); setShowNewAdminModal(true) }}>
                            <AddUserIcon />
                        </button>
                    </div>
                </div>
                <div className="-mr-6 -ml-6 airpark-scroll overflow-x-scroll">
                    <table className="table-auto mt-3 w-full">
                        <thead>
                            <tr className="text-left bg-airpark-gray-300 h-11">
                                <th className="pl-6 whitespace-nowrap w-12"></th>
                                <th className="pl-6 whitespace-nowrap w-1/12">Id</th>
                                <th className="pl-6 whitespace-nowrap">Name</th>
                                <th className="pl-6 whitespace-nowrap">Email</th>
                                <th className="pl-6 whitespace-nowrap"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAdmins && filteredAdmins.slice(currentAdminPage * 10, ((currentAdminPage * 10) + 10)).map((item, i) => {
                                return (<AdminItem key={i} admin={item} searchText={searchText} handleAdminRemoved={handleAdminRemoved} />)
                            })}
                        </tbody>
                    </table>
                    <div className="p-6">
                        <Paging
                            totalNumberOfPages={adminPages}
                            currentPage={currentAdminPage}
                            setCurrentPage={setCurrentAdminPage} />
                    </div>
                </div>
                <NewAdminModal
                    display={showNewAdminModal}
                    setDisplay={(display) => { setShowNewAdminModal(display); }}
                    adminCreated={handleAdminCreated}>
                </NewAdminModal>
            </div>
        );
    } else {
        return (
            <LoadingSkeleton />
        )
    }
};

const AdminItem = ({ admin, searchText, handleAdminRemoved }) => {

    const { getAccessTokenSilently } = useAuth0();
    const [isOptionsLoading, setIsOptionsLoading] = useState();
    const [showOptions, setShowOptions] = useState();
    const { showToast } = useMessages();

    const handleRemoveAdminRights = async () => {
        if (isOptionsLoading) {
            return;
        }

        setShowOptions(false);
        setIsOptionsLoading(true);
        try {
            var token = await getAccessTokenSilently();
            const success = await removeAdmin(token, admin.userExtId);

            if (success) {
                await handleAdminRemoved(admin.name);
            } else {
                console.error(success);
                showToast('Admin removal failed!', `Failed to remove admin rights from '${admin.name}'!`, 'error');
            }
        } catch (error) {
            console.error(error);
        }
        setIsOptionsLoading(false);
    }

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6">
                <a className="flex w-12" href={admin.picture} target="_blank">
                    <img className="shadow rounded-xl" src={admin.picture} />
                </a>
            </td>
            <td className="pl-6">{admin.userExtId}</td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={admin.name}
                />
            </td>
            <td className="pl-6">
                <Highlighter
                    searchWords={[searchText]}
                    textToHighlight={admin.email}
                />
            </td>
            <td className="border-l-[1px] border-airpark-gray-300 min-w-[72px] w-[72px] relative">
                {isOptionsLoading &&
                    <div className="justify-center items-center">
                        <Lottie className="h-6 w-full" animationData={animSpinnerGreen} loop={true} />
                    </div>
                }
                {!isOptionsLoading &&
                    <button className="h-6 w-full flex justify-center items-center" onClick={(e) => { e.preventDefault(); setShowOptions(!showOptions); }}>
                        <ThreeDotsIcon className="block" />
                    </button>
                }
                {showOptions &&
                    <Menu onCloseClick={() => { setShowOptions(false); }}>
                        <button className="flex items-center" onClick={async (e) => { e.preventDefault(); await handleRemoveAdminRights(); }}>
                            Remove admin rights
                        </button>
                    </Menu>
                }
            </td>
        </tr>
    )
}

export default AdminsPage;