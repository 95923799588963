import React from "react";
import { ReactComponent as CheckIcon } from 'assets/icons/ico_check.svg';

const CheckBox = ({ isChecked, onClick }) => {
    return (
        <div className="flex relative cursor-pointer text-lg select-none h-[24px] w-[24px]">
            <input className="flex absolute opacity-0 cursor-pointer" type="checkbox" checked={isChecked} onChange={(e) => { /* Ignore on change */ }} />
            <div onClick={(e) => { onClick(); }} className="flex items-center justify-center absolute top-0 left-0 h-[24px] w-[24px] bg-airpark-gray-background-opacity-20 hover:bg-airpark-gray-background-opacity-30 rounded-[10px]">
                {isChecked &&
                    <CheckIcon />
                }
            </div>
        </div>
    );
}

export default CheckBox;