import React from 'react';
import Tooltip from '../tooltip';

const ButtonSlider = ({ title, isToggled, onClick, tooltip }) => {
    return (
        <div className="flex items-center">
            <button onClick={(e) => { e.preventDefault(); onClick(); }} className={`flex relative w-[52px] h-[32px] rounded-2xl items-center ${isToggled ? 'bg-airpark-green' : 'bg-airpark-gray-300'}`}>
                <div className={`absolute transition-all duration-200 h-[28px] w-[28px] rounded-full bg-white shadow ${isToggled ? 'left-[22px]' : 'left-[2px]'}`}></div>
            </button>
            {tooltip &&
                <div className="flex grow relative justify-betweem items-center">
                    <span className="font-medium ml-5">{title}</span>
                    <Tooltip tooltip={tooltip} />
                </div>
            }
            {!tooltip &&
                <span className="font-medium ml-5">{title}</span>
            }
        </div>
    );
};

export default ButtonSlider;