import React, { useState, useEffect } from 'react'
import { getPoints, postPoint, deletePoint } from 'api/admin';
import InputText from 'components/common/inputText';
import ButtonText from 'components/common/buttonText';
import { useMessages } from 'context/messages';
import LoadingSkeleton from 'components/common/loadingSkeleton';
import CheckBox from 'components/common/checkbox';
import Menu from 'components/dashboard/menu/menu';
import { ThreeDotsIcon } from 'components/icons/icons';

const Point = ({ id, answer: a = '', question: q = '', points = [], uploadNew = false, setPoints = () => { }, loadPoints = () => { } }) => {
    const [question, setQuestion] = useState(q ?? '');
    const [answer, setAnswer] = useState(a ?? '');
    const [isAddingPoint, setIsAddingPoint] = useState(false);
    const [isRemovingPoint, setIsRemovingPoint] = useState(false);
    const [useGenAI, setUseGenAI] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const { showToast } = useMessages();

    const addPoint = async () => {
        setIsAddingPoint(true)
        const point = {
            answer,
            question
        }

        try {
            await postPoint({ token: 'test', newPoint: point, useGenAI: useGenAI });
            loadPoints()
            showToast('Success', "Successfully added new Point", 'success')
        } catch (e) {
            showToast('Error', `Could not add new point: ${e}`, 'error')
        }
        setIsAddingPoint(false);
        setQuestion('');
        setAnswer('');
        setUseGenAI(false);
    }

    const removePoint = async (id) => {
        setIsRemovingPoint(true);

        try {
            await deletePoint({ token: 'test', pointId: id });
            loadPoints()
            showToast('Success', 'Successfully removed point', 'success');
        } catch (e) {
            showToast('Error', `Could not remove point: ${e}`, 'error')
        }
        setIsRemovingPoint(false)
    }

    return (
        <div key={id ?? q ?? 'default'} className="flex flex-col gap-y-6 my-2">
            <div className="flex relative flex-col w-full rounded-xl shadow-lg">
                <div className='flex w-full'>
                    <div className="flex flex-col mx-2 w-1/2 py-6">
                        <h1>Question</h1>
                        <InputText placeHolder={"Write your question here..."} value={question} onChange={(val) => setQuestion(val)} />
                    </div>
                    <div className="flex flex-col mx-2 w-1/2 py-6">
                        <h1>Answer</h1>
                        <InputText placeHolder={"Write your answer here..."} value={answer} onChange={(val) => setAnswer(val)} />
                    </div>
                    {
                        !uploadNew && (
                            <div className="relative flex flex-col mx-2 border-l-[1px] py-6" h-full>
                                <button className="h-full p-2 w-full flex justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(prev => !prev) }}>
                                    <h1>&thinsp;</h1>
                                    <ThreeDotsIcon className="block" />
                                </button>
                                {showMenu && (
                                    <Menu onCloseClick={() => { setShowMenu(prev => !prev) }}>
                                        <button className="flex items-center" onClick={async (e) => {
                                            e.preventDefault(); removePoint(id);
                                        }}>
                                            Remove
                                        </button>
                                    </Menu>
                                )}
                            </div>
                        )
                    }

                </div>
                {uploadNew && (
                    <div className="flex w-full justify-end mt-6 ml-3 px-6 pb-6">
                        <div className="flex m-2 mx-6">
                            <span className='mx-2 m-auto text-center'>Använd GenAI</span>
                            <CheckBox
                                isChecked={useGenAI}
                                onClick={() => setUseGenAI(prev => !prev)}
                                className="max-w-xs w-full" />
                        </div>

                        <ButtonText
                            className="max-w-xs w-full"
                            isLoading={isAddingPoint}
                            disabled={isAddingPoint}
                            onClick={addPoint}>
                            Add
                        </ButtonText>
                    </div>
                )}
            </div>
        </div>
    )
}


const SupportBotPage = (props) => {
    const [points, setPoints] = useState([])
    const [loading, setLoading] = useState(false);
    const { showToast } = useMessages();



    const loadPoints = async () => {
        try {
            console.log('LOADING');
            const data = await getPoints({ token: 'test' });
            setPoints(data);
            console.log('DATA: ', data);
        } catch (err) {
            showToast('Error', `Something went wrong: ${err}`, 'error')
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        loadPoints();
    }, []);


    if (!loading) {
        return (
            <>
                <Point uploadNew={true} points={points} setPoints={setPoints} loadPoints={loadPoints} />
                {points.map(({ id, payload = {} }) => <Point key={id} id={id} answer={payload.answer} setPoints={setPoints} loadPoints={loadPoints} question={payload.question} />)}
            </>
        )
    } else {
        return (
            <LoadingSkeleton />
        )
    }
}

export default SupportBotPage