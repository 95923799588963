import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/ico_info.svg';

const Tooltip = ({tooltip}) => {
    return (
        <div className="flex absolute right-0 h-11 w-11 items-center justify-center cursor-pointer -mr-1">
            <InfoIcon className="absolute h-5 w-5" />
            <div className="flex h-11 w-11 hover:opacity-100 hover:scale-100 scale-90 opacity-0 transition-all duration-500 ease-in-out items-center justify-center">
                <div className="relative">
                    <span className="absolute w-[348px] bg-white bottom-[100%] left-[50%] -ml-[320px] text-center mb-6 p-6 shadow-lg rounded-xl pointer-events-none">
                        {tooltip}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Tooltip;